import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HeaderBig from "../Contents/HeaderBig";
import MenuSmall from "../Contents/MenuSmall";
import Footer from "../Layout/Footer/footer";
import Header from "../Layout/Header/Header";
import Background from "../../../src/assets/img/hero/breadcumbBg.png";
import ProductDto from "../Dtos/ProductDto";
import Pagination from "../Contents/Pagination";
import { staticAssets } from "../staticAssets";

const Urunler: React.FC = () => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("pg"));
  const pageSize = Number(searchParams.get("ps"));
  const [totalCount, setTotalCount] = useState<number>(0);
  const [Product, setProducts] = useState<ProductDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const url = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    fetch(url+"Product/GetPagingProduct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pageSize: pageSize === null ? 12 : pageSize,
        page: page === null ? 1 : page,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if(currentPage == 1){console.log("")}
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setProducts(data.result);
        setTotalCount(data.totalCount);
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  return (
    <div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <div>
        <div className="breadcrumb-wrapper">
          <div
            className="breadcumb"
            style={{ background: `url(${Background})` }}
          >
            <div className="container">
              <div className="page-heading">
                <h1 className="wow fadeInUp" data-wow-delay=".3s">
                  {}
                </h1>
                <ul
                  className="breadcrumb-items wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <li>
                    <a href="/">Anasayfa</a>
                  </li>
                  <li>
                    <i className="fas fa-chevrons-right"></i>
                  </li>
                  <li>Ürünlerimiz</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="team-area">
          <div className="team-wrap style2  space-top pb-200 fix mb-100">
            <div className="container">
              <div className="row">
                {Product.map((item, index) => (
                  <div className="col-xl-3 col-md-6 col-12 mt-30" key={index}>
                    <div
                      className="team-card style2 mb-30 wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <div className="team-thumb">
                        <img src={item.productImage} alt="team-img" />
                      </div>
                      <div className="team-content text-center">
                        <p>
                          <a href="" style={{ color: "#6c757d" }}>
                            {item.productName}
                          </a>
                        </p>
                        {/* <h3>
                          <a href="team-details.html">
                            {item.productName}
                          </a>
                        </h3> */}
                        <p>
                          <b>{item.productPrice} ₺</b>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <section className="news-standard fix space-top pb-200">
            <div className="container">
              <div className="row g-4">
                <div className="col-12 col-lg-12 mb-100">
                  <div className="news-standard-wrapper">
                    <div
                      className="page-nav-wrap pt-5 text-center wow fadeInUp"
                      data-wow-delay=".8s"
                    >
                      <Pagination 
                      currentPage = {page}
                        totalItems={totalCount}
                        itemsPerPage={12}
                        onPageChange={(pg) => setCurrentPage(pg)}
                      />
                      <ul>
                        {/* <li>
                          <a className="page-numbers" href="#">
                            <i className="fa-sharp fa-light fa-arrow-left-long"></i>
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            01
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            02
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            03
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            03
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            03
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            03
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            <i className="fa-sharp fa-light fa-arrow-right-long"></i>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section
          className="cta-area  mb-n116  wow fadeInUp"
          data-wow-delay=".1s"
        >
          <div className="container">
            <div className="cta-wrap style1">
              <div className="shape1_1 rotate360 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_1}
                  alt="shape"
                />
              </div>
              <div className="shape1_2 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_2}
                  alt="shape"
                />
              </div>
              <div className="shape1_3 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_3}
                  alt="shape"
                />
              </div>

              <h4 className="cta-title text-white">
                Yenilikçi Teknolojilerimizle Bağlantıda Kalın
              </h4>
              <div className="btn-wrapper">
                <a className="gt-btn style5 gt-btn-icon " href="/iletisim">
                  BİZE ULAŞIN
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Urunler;
