const formatTitle = (title: string) => {
    return title
      .toLowerCase()
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/Ğ/g, 'g')
      
      .replace(/[^a-z0-9-]+/gi, '-') // Hem küçük hem büyük harfleri kontrol etmek için 'gi' bayrağını kullan
      .replace(/^-+|-+$/g, ''); // Başta ve sonda kalan "-" işaretlerini temizle
  };

  export default formatTitle;

