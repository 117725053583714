import { useState, useEffect } from "react";
import AboutUsDto from "../Dtos/AboutUsDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
 const HakkimizdaComp = () => {

   const [aboutus,setAboutus] = useState<AboutUsDto>();
    
   useEffect(() => {
     fetch(url + "AboutUs/GetAboutUs", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
     })
       .then((response) => {
         if (!response.ok) {
           throw new Error("Network response was not ok");
         }
         return response.json();
       })
       .then((data) => {
         //console.log(data.mail);
         setAboutus(data)
         console.log(aboutus)
         //data.sliders.map((item)=> console.log(item))
         
       })
       .catch((error) => console.log("Error fetching images:", error));
   }, []);

   
  return (

<div>
<div className="breadcrumb-wrapper">
         <div className="breadcumb" style={{ background: `url(${staticAssets.PageHeader_1})`, backgroundRepeat:"no-repeat" }} >
            <div className="container">
               <div className="page-heading">
                  <h1 className="wow fadeInUp" data-wow-delay=".3s">Hakkımızda</h1>
                  <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                     <li>
                        <a href="/">
                        Anasayfa
                        </a>
                     </li>
                     <li>
                        <i className="fas fa-chevrons-right"></i>
                     </li>
                     <li>
                        Hakkımızda
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
    <div className="about-area space fix position-reltive">
      <div className="about-wrap style1">
        <div className="shape1_1 d-xl-block d-none">
          <img src={staticAssets.AboutShape_1} alt="shape" />
        </div>
        <div className="shape1_2 movingX d-xl-block d-none">
          <img src={staticAssets.AboutShape_2} alt="shape" />
        </div>
        <div className="container">
          <div className="about-wrapper style1">
            {/* <div className="about-thumb-box wow fadeInUp" data-wow-delay=".6s">
              <div className="about-thumb-box about-thumb1">
                <img
                  src="src/assets/img/logo.png"
                  alt="thumb"
                  style={{ maxWidth: "80%" }}
                />
              </div>

              <div className="about-thumb-box line jump"></div>
            </div> */}
            <div className="about-content wow fadeInUp" data-wow-delay="1.3s">
              <div className="title-area">
              
              
               <h3>Hakkımızda</h3>
              {aboutus?.aboutUs}
              <hr/>
              <h3>Vizyonumuz</h3>
              {aboutus?.ourVision}
              <hr/>
              <h3>Misyonumuz</h3>
              {aboutus?.ourMission}
              <hr/>
              <h3>Bilgi Güvenliği</h3>
              {aboutus?.informationSecurity}
              <hr/>
              <h3>Kvkk Hakkında</h3>
              {aboutus?.kvkkDescription} 
              <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default HakkimizdaComp;
