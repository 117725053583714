import React from "react";
import HeaderBig from "../Contents/HeaderBig";
import MenuSmall from "../Contents/MenuSmall";
import Footer from "../Layout/Footer/footer";
import Header from "../Layout/Header/Header";
import IletisimComp from "../Contents/IletisimComp";



const Iletisim: React.FC = () => {
  return (
    <div>
      <div className="cursor-follower"></div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <IletisimComp></IletisimComp>
      <Footer></Footer>
    </div>
  );
};

export default Iletisim;
