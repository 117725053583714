import { useState, useEffect } from "react";
import formatTitle from "./BaseFunction";
import BaseContent from "../Dtos/BaseContentsDto";
import ServiceDto from "../Dtos/ServiceDto";
const url = process.env.REACT_APP_BACKEND;
const MenuSmall = () => {
  const [services, setServices] = useState<ServiceDto[]>([]);

  useEffect(() => {
    fetch(url + "services/GetServices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setServices(data.ourService);
        //console.log(contents?.Address)
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  const [contents, setContents] = useState<BaseContent>();

  useEffect(() => {
    fetch(url + "baseContent/GetBaseContent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setContents(data);
        //console.log(contents?.Address)
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);
  return (
    <div className="gt-menu-wrapper onepage-nav">
      <div className="gt-menu-area text-center">
        <button className="gt-menu-toggle">
          <i className="fal fa-times"></i>
        </button>
        <div className="mobile-logo">
          <a href="/">
            <img src={contents?.logoPath} alt={contents?.companyName} />
          </a>
        </div>
        <nav className="gt-mobile-menu">
          <ul>
            <li>
              <a href="/">Anasayfa</a>
            </li>
            <li>
              <a href="/hakkimizda">Hakkımızda</a>
            </li>
            <li className="menu-item-has-children">
              <a href=""> Hizmetlerimiz </a>
              <ul className="sub-menu">
                {services.map((item, index) => (
                  <li key={index}>
                    {" "}
                    <a
                      href={
                        "hizmetlerimiz/" +
                        formatTitle(item.serviceTitle) +
                        "/" +
                        item.id
                      }
                    >
                      {item.serviceTitle}
                    </a>
                  </li>
                ))}
                ;
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="/garanti-sorgulama"> Destek </a>
              <ul className="sub-menu">
                <li>
                  <a href="/garanti-sorgulama"> Garanti Sorgulama </a>
                </li>
                <li>
                  <a href="/servis-kaydi-sorgulama"> Servis Kaydı Sorgulama </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/urunler"> Ürünlerimiz </a>
            </li>

            <li>
              <a href="/iletisim">İletişim</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MenuSmall;
