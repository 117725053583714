import React, { useState, useEffect } from "react";
import { useSearchParams,useNavigate  } from "react-router-dom";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage:number;
  onPageChange: (pageNumber: number) => void;
  
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  currentPage,
}) => {
  const [pages, setPages] = useState<number[]>([]);
  const [searchParams] = useSearchParams();
  const page = searchParams.get("pg");
  const navigate = useNavigate();
    // Veriyi kontrol et
    
  
  useEffect(() => {
    const totalPages = Math.ceil(totalItems / 13);
    if (totalItems > 0) {
      if (currentPage > totalPages) {
        navigate('/404'); // Uygun değilse 404 sayfasına yönlendir
      } else {
        setPages(Array.from({ length: totalPages }, (_, i) => i + 1));
      }
    }
    
    setPages(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [totalItems, itemsPerPage]);
  return (
    <ul>
        {page === '1'
        ?  ""
        : <li>
        <a className="page-numbers" href={"urunler?pg=" + (--currentPage) + "&ps=12"}>
            <i className="fa-sharp fa-light fa-arrow-left-long"></i>
          </a>
        </li>
      }
       
      
      
      {pages.map((page, index) => (
        <li key={index} onClick={() => onPageChange(page)} >
          <a className={currentPage === index ? "page-numbers current" : "page-numbers"} href={"urunler?pg=" + page + "&ps=12"} >
            {page}
          </a>
        </li>
      ))}

{
        Math.ceil(totalItems / 13).toString() === page?.toString()
        ?  ""
        : <li>
        <a className="page-numbers" href={"urunler?pg=" + (++currentPage) + "&ps=12"}>
          <i className="fa-sharp fa-light fa-arrow-right-long"></i>
        </a>
      </li>
      }
      
    </ul>
  );
};

export default Pagination;
