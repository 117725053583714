import "./assets/css/swiper-bundle.min.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/main.css";
import "jquery";
import "bootstrap";
//import './assets/js/tilt.min.js';
//import './assets/js/bootstrap.min.js';
// import './assets/js/swiper-bundle.min.js';
// import './assets/js/jquery.magnific-popup.min.js';
// import './assets/js/jquery.counterup.min.js';
// import './assets/js/bootstrap.min.js';
// import './assets/js/jquery.waypoints.min.js';
//
// //import 'https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js';

// //import './assets/js/gsap.min.js';
//import './assets/js/main.js';

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import index from "./components/pages";
import Services from "./components/pages/services";
import Hakkimizda from "./components/pages/hakkimizda";
import Hizmetlerimiz from "./components/pages/hizmetlerimiz";
import Urunler from "./components/pages/urunler";
import Iletisim from "./components/pages/iletisim";
import Hata from "./components/pages/hata";
import ErrorBoundary from "./components/Contents/ErrorBoundary";
import Haberler from "./components/pages/haberler";
import HaberOzel from "./components/pages/haberozel";
// import Header from "./components/Layout/Header/Header.js";
// import MainSlider from "./components/Contents/MainPageSlider.js";
// import Footer from "./components/Layout/Footer/footer.js";
const App: React.FC = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" Component={index} />
          <Route path="/hakkimizda" element={<Hakkimizda />} />
          <Route path="/hizmetlerimiz/:title/:id" element={<Services />} />
          <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />
          <Route path="/urunler" element={<Urunler />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/haberler" element={<Haberler />} />
          <Route path="/haber/:title/:id" element={<HaberOzel />} />
          <Route path="*" element={<Hata />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
