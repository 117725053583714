import { useState, useEffect } from "react";
import Background from "../../../src/assets/img/hero/breadcumbBg.png";
import ServiceDto from "../Dtos/ServiceDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
const HizmetlerimizComp = () => {
  const [ourServices, setOurServices] = useState<ServiceDto[]>([]);

  useEffect(() => {
    fetch(url + "Services/GetServices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setOurServices(data.ourService);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  function truncateText(text: string): React.ReactNode {
    if (text.length > 90) {
      return text.substring(0, 90) + "...";
    }
    return text;
  }

  return (
    <div>
      <div className="breadcrumb-wrapper">
        <div className="breadcumb" style={{ background: `url(${Background})` }}>
          <div className="container">
            <div className="page-heading">
              <h1 className="wow fadeInUp">Hizmetlerimiz</h1>
              <ul className="breadcrumb-items wow fadeInUp">
                <li>
                  <a href="/">Anasayfa</a>
                </li>
                <li>
                  <i className="fas fa-chevrons-right"></i>
                </li>
                <li>Hizmetlerimiz</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="all-services-area space-top">
        <div className="container">
          <div className="row">
            {ourServices.map((item, index) => (
              <div className="col-12 col-md-6 col-xl-3" key={index}>
                <div
                  className="service-card style3 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="card_icon">
                    <img
                      src={staticAssets.ServiceIcon_1_1}
                      alt="icon"
                    />
                  </div>
                  <div className="card_content">
                    <h3>
                      <a
                        href={"hizmetlerimiz/" + item.link + "/" + item.id}
                        className="title"
                      >
                        {item.serviceTitle}
                      </a>
                    </h3>
                    <p className="text">
                      {truncateText(item.serviceDescription)}
                    </p>
                  </div>
                  <div className="link-wrap">
                    <a href={"/hizmetlerimiz/" + item.link + "/" + item.id}>
                      Devamı...
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="textslider-area space mt-n30">
        <div className="mycustom-marque">
          <div className="scrolling-wrap">
            <div className="textWrapper">
              {ourServices.map((item, index) => (
                <div
                  className={
                    "textWrapper-textslide " +
                    (index % 2 === 0 ? "text-custom-storke" : "")
                  }
                  key={index}
                >
                  <img src={staticAssets.starIcon_3} alt="img" />
                  {item.serviceTitle}
                </div>
              ))}
              {/* <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Cyber Security</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>IT
                  Solution
               </div>
               <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Technology</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>Data
                  Security
               </div>
               <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Cyber Security</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>IT
                  Solution
               </div>
               <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Cyber Security</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>IT
                  Solution
               </div> */}
            </div>
            {/* <div className="textWrapper">
               <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Cyber Security</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>IT
                  Solution
               </div>
               <div className="textWrapper-textslide text-custom-storke"><img src="src/assets/img/icon/starIcon3.png"
                  alt="img"/>Technology</div>
               <div className="textWrapper-textslide"><img src="src/assets/img/icon/starIcon3.png" alt="img"/>Data
                  Security
               </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HizmetlerimizComp;
