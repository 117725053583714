import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderBig from "../Contents/HeaderBig";
import MenuSmall from "../Contents/MenuSmall";
import Footer from "../Layout/Footer/footer";
import Header from "../Layout/Header/Header";
import Background from "../../../src/assets/img/hero/breadcumbBg.png";
import BaseContent from "../Dtos/BaseContentsDto";
import ServiceDto from "../Dtos/ServiceDto";
import { staticAssets } from "../staticAssets";


const Services: React.FC = () => {
  const { id } = useParams();
  const [baseContent, setBaseContent] = useState<BaseContent>();
  const [ourService, setOurService] = useState<ServiceDto>();
  const [ourServices, setOurServices] = useState<ServiceDto[]>([]);
  const url = process.env.REACT_APP_BACKEND;
  useEffect(() => {
    fetch(url+"Services/GetService", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setOurService(data.ourService);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  useEffect(() => {
    fetch(url+"Services/GetServices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setOurServices(data.ourService);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  useEffect(() => {
    fetch(url+"BaseContent/GetBaseContent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setBaseContent(data);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);
  return (
    <div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <div>
        <div className="breadcrumb-wrapper">
          <div
            className="breadcumb"
            style={{ background: `url(${Background})` }}
          >
            <div className="container">
              <div className="page-heading">
                <h1 className="wow fadeInUp" data-wow-delay=".3s">
                  {}
                </h1>
                <ul
                  className="breadcrumb-items wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <li>
                    <a href="/">Anasayfa</a>
                  </li>
                  <li>
                    <i className="fas fa-chevrons-right"></i>
                  </li>
                  <li>
                    <a href="/hizmetlerimiz">Hizmetlerimiz</a>
                  </li>
                  <li>
                    <i className="fas fa-chevrons-right"></i>
                  </li>
                  <li>{ourService?.serviceTitle}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="service-details-section space-top pb-425 fix">
          <div className="container">
            <div className="service-details-wrapper">
              <div className="row g-4">
                <div className="col-12 col-lg-4 order-2 order-md-1">
                  <div className="main-sidebar">
                    <div
                      className="single-sidebar-widget wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <div className="wid-title">
                        <h3>Hizmetlerimiz</h3>
                      </div>
                      <div className="news-widget-categories">
                        <ul>
                          {ourServices.map((item, index) => (
                            <li key={index}  className={
                              (item.id === id ? "active" : "")
                            }>
                              <a
                                href={'/hizmetlerimiz/'+ item.link + '/'+ item.id }
                              >
                                {item.serviceTitle}
                                <span>
                                  <i className="fa-light fa-arrow-right-long"></i>
                                </span>
                              </a>
                            </li>
                          ))}

                         
                        </ul>
                      </div>
                    </div>
                    <div
                      className="single-sidebar-widget wow fadeInUp"
                      data-wow-delay=".8s"
                    >
                      <div className="wid-title">
                        <h3>Çalışma Saatleri</h3>
                      </div>
                      <div className="opening-category">
                        <ul>
                          <li>
                            <i className="fa-regular fa-clock"></i>
                            Pazartesi - Cuma : 
                            {baseContent?.weekWorkHours}
                          </li>
                          <li>
                            <i className="fa-regular fa-clock"></i>
                            Cumartesi - Pazar : 
                            {baseContent?.weekendWorkHours}
                          </li>
                          {/* <li><i className="fa-regular fa-clock"></i>Sun:  09.00 AM - 4.00 PM</li>
                              <li><i className="fa-regular fa-clock"></i>Friday: Closed</li>
                              <li><i className="fa-regular fa-clock"></i>Emergency: 24 hours</li> */}
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-12 col-lg-8 order-1 order-md-2">
                  <div className="service-details-items">
                    <div className="details-image">
                      <img
                        src={ourService?.imageInside}
                        alt="img"
                      />
                    </div>
                    <div className="details-content">
                      <h3 className="wow fadeInUp" data-wow-delay=".6s">
                        {ourService?.serviceTitle}
                      </h3>
                      {ourService?.serviceDescription}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cta-area mt-n150 mb-n116  wow fadeInUp" data-wow-delay=".1s">
         <div className="container">
            <div className="cta-wrap style1">
               <div className="shape1_1 rotate360 d-none d-xl-block"><img src={staticAssets.CtaShape_1}
                  alt="shape"/></div>
               <div className="shape1_2 d-none d-xl-block"><img src={staticAssets.CtaShape_2} alt="shape"/>
               </div>
               <div className="shape1_3 d-none d-xl-block"><img src={staticAssets.CtaShape_3} alt="shape"/>
               </div>
               
               <h4 className="cta-title text-white">Yenilikçi Teknolojilerimizle Bağlantıda Kalın</h4>
               <div className="btn-wrapper">
                  <a className="gt-btn style5 gt-btn-icon " href="/iletisim">BİZE ULAŞIN</a>
               </div>
            </div>
         </div>
      </section>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Services;
