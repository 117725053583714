import React, { useEffect, useState } from "react";
import Background from "../../assets/img/bg/serviceCardBg2_1.png";
import ServiceDto from "../Dtos/ServiceDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;

const MainServices = () => {
  const [services, setServices] = useState<ServiceDto[]>([]);
  useEffect(() => {
    fetch(url + "Services/GetServices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setServices(data.ourService)
        //console.log(contents?.Address)
        //data.sliders.map((item)=> console.log(item))
        
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);


  function truncateText(text: string): React.ReactNode {
    if (text.length > 110) {
      return text.substring(0, 110) + "...";
    }
    return text;
  }

  return (
    <section
      className="service-area space mt-1 fix"
      style={{ background: `url(${Background})` }}
    >
      <div className="container">
        <div className="title-area mx-auto">
          {/* <h5 className="subtitle text-center  wow fadeInUp" data-wow-delay=".2s"> <span><img src="src/assets/img/icon/titleIcon.png"
             alt="icon"/></span> Our Feathered Services <span><img
             src="src/assets/img/icon/titleIcon.png" alt="icon"/></span> </h5> */}
          <h2
            className="title text-center mb-50 wow fadeInUp"
            data-wow-delay=".4s"
          >
            Hizmetlerimiz
          </h2>
        </div>
        <div className="service-card-wrapper style2">
          {services.map((item, index) => (
            <div
              className="service-card style2 wow fadeInUp"
              data-wow-delay={'.'+index+'s'}
              key={index}
            >
              <div className="service-thumb">
                <img
                  className="img-shine "
                  src={item?.serviceImage}
                  alt="thumb"
                />
              </div>
              <div className="service-content">
                <h3 className="service-content_title">
                  <a href={'hizmetlerimiz/'+ item.link + '/'+ item.id }>{item?.serviceTitle}</a>
                </h3>
                <p className="service-content_text">
                {truncateText(item?.serviceDescription)}
                </p>
                <a className="link" href={'hizmetlerimiz/'+ item.link + '/'+ item.id }>
                  Detay
                  <i className="fa-sharp fa-light fa-arrow-right-long"></i>
                </a>
              </div>
              <div className="service-icon">
                <img src={staticAssets.ServiceIcon_2_1} alt="icon" />
              </div>
              <div className="service-icon_two ripple">
                <img src={staticAssets.ServiceIcon_2_5} alt="icon" />
              </div>
            </div>
          ))}
          ;
        </div>
      </div>
    </section>
  );
};

export default MainServices;
