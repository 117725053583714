 import  { useEffect, useState } from "react";
import BaseContent from "../Dtos/BaseContentsDto";
import { staticAssets } from "../staticAssets";
import CountUp  from 'react-countup';
// import Background from "../../assets/img/bg/serviceCardBg2_1.png";
const url = process.env.REACT_APP_BACKEND;
// Ortak fetch fonksiyonu


const MainCounterArea = () => {
    const [contents,setContents] = useState<BaseContent>();
    
    useEffect(() => {
      fetch(url + "basecontent/GetBaseContent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          //console.log(data.mail);
          setContents(data)
          //console.log(contents?.Address)
          //data.sliders.map((item)=> console.log(item))
          
        })
        .catch((error) => console.log("Error fetching images:", error));
    }, []);


  return (

     <div className="counter-area mb-n116 mt-n150 fix ">
    <div className="container">
       <div className="counter-wrap">
          <div className="counter-box style2 wow fadeInUp" data-wow-delay=".3s">
             <div className="counter-box_icon">
                <img src={staticAssets.ServiceIcon_2_1} alt="icon"/>
             </div>
             <div className="counter-box_counter">
                <div>
                  
                   <span className="counter-number"><CountUp className="counter-number" end={contents?.productCount} duration={3} /></span> <span className="plus">+</span>
                </div>
                <span>Ürün</span>
             </div>
          </div>
          <div className="counter-box style2 wow fadeInUp" data-wow-delay=".6s">
             <div className="counter-box_icon">
                <img src={staticAssets.CounterIcon_1_1} alt="icon"/>
             </div>
             <div className="counter-box_counter ">
                <div>
                <span className="counter-number"><CountUp className="counter-number" end={contents?.workerCount} duration={3} /></span> <span className="plus">+</span>
                </div>
                <span>Ekip Arkadaşı</span>
             </div>
          </div>
          <div className="counter-box style2 wow fadeInUp" data-wow-delay=".9s">
             <div className="counter-box_icon">
                <img src={staticAssets.CounterIcon_1_2} alt="icon"/>
             </div>
             <div className="counter-box_counter ">
                <div>
                <span className="counter-number"><CountUp className="counter-number" end={contents?.projectCount} duration={3} /></span> <span className="plus">+</span>
                </div>
                <span>Proje</span>
             </div>
          </div>
          <div className="counter-box style2 wow fadeInUp" data-wow-delay="1s">
             <div className="counter-box_icon">
                <img src={staticAssets.CounterIcon_1_3} alt="icon"/>
             </div>
             <div className="counter-box_counter ">
                <div>
                <span className="counter-number"><CountUp className="counter-number" end={contents?.year} duration={3} /></span> <span className="plus">+</span>
                </div>
                <span>Yıllık Deneyim</span>
             </div>
          </div>
       </div>
    </div>
 </div>
  );
};

export default MainCounterArea;