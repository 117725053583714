
import MainNews from "./MainNews";
import Background from "../../../src/assets/img/hero/breadcumbBg.png";
const HaberlerComp = () => {


  return (
    <div>
<div className="breadcrumb-wrapper">
        <div className="breadcumb" style={{ background: `url(${Background})` }}>
          <div className="container">
            <div className="page-heading">
              <h1 className="wow fadeInUp">Haberler</h1>
              <ul className="breadcrumb-items wow fadeInUp">
                <li>
                  <a href="/">Anasayfa</a>
                </li>
                <li>
                  <i className="fas fa-chevrons-right"></i>
                </li>
                <li>Haberler</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
<MainNews></MainNews>
    </div>
  );
};

export default HaberlerComp;
