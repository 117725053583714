import Background from "../../../src/assets/img/hero/breadcumbBg.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NewsDto from "../Dtos/NewsDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
const HaberOzelComp = () => {
  const { id } = useParams();
  const [news, setNews] = useState<NewsDto>();
  const [newsList, setNewsList] = useState<NewsDto[]>([]);
  const [htmlContent, setHtmlContent] = useState("");
  useEffect(() => {
    fetch(url +  "News/GetNew", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setNews(data);
        setHtmlContent(data.contents);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  useEffect(() => {
    fetch(url +  "News/GetNews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pageSize: 5, page: 0, orderBy: "asc" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setNewsList(data.news);
        console.log(data);
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);
  return (
    <div>
      <div>
        <div className="breadcrumb-wrapper">
          <div
            className="breadcumb"
            style={{ background: `url(${Background})` }}
          >
            <div className="container">
              <div className="page-heading">
                <h1 className="wow fadeInUp" data-wow-delay=".3s">
                  {}
                </h1>
                <ul
                  className="breadcrumb-items wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <li>
                    <a href="/">Anasayfa</a>
                  </li>
                  <li>
                    <i className="fas fa-chevrons-right"></i>
                  </li>
                  <li>
                    <a href="/haberler">Haberler</a>
                  </li>
                  <li>
                    <i className="fas fa-chevrons-right"></i>
                  </li>
                  <li>{news?.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="service-details-section space-top pb-425 fix">
          <div className="container">
            <div className="service-details-wrapper">
              <div className="row g-4">
                <div className="col-12 col-lg-4 order-2 order-md-1">
                  <div className="main-sidebar">
                    <div
                      className="single-sidebar-widget wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <div className="wid-title">
                        <h3>Haberler</h3>
                      </div>
                      <div className="news-widget-categories">
                        <ul>
                          {newsList.map((item, index) => (
                            <li
                              key={index}
                              className={item.id === id ? "active" : ""}
                            >
                              <a
                                href={"/haber/" + item.link + "/" + item.id}
                              >
                                {item.title}
                                <span>
                                  <i className="fa-light fa-arrow-right-long"></i>
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="col-12 col-lg-8 order-1 order-md-2">
                  <div className="service-details-items">
                    <div className="details-image">
                      <img src={news?.image} alt={news?.link} />
                      
                    </div>
                    <div className="details-content">
                      <h3 className="wow fadeInUp" data-wow-delay=".6s">
                        {news?.title}
                      </h3>
                      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="cta-area mt-n150 mb-n116  wow fadeInUp"
          data-wow-delay=".1s"
        >
          <div className="container">
            <div className="cta-wrap style1">
              <div className="shape1_1 rotate360 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_1}
                  alt="shape"
                />
              </div>
              <div className="shape1_2 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_2}
                  alt="shape"
                />
              </div>
              <div className="shape1_3 d-none d-xl-block">
                <img
                  src={staticAssets.CtaShape_3}
                  alt="shape"
                />
              </div>

              <h4 className="cta-title text-white">
                Yenilikçi Teknolojilerimizle Bağlantıda Kalın
              </h4>
              <div className="btn-wrapper">
                <a className="gt-btn style5 gt-btn-icon " href="/iletisim">
                  BİZE ULAŞIN
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HaberOzelComp;
