

import React from "react";
import HeaderBig from "../Contents/HeaderBig";
import MenuSmall from "../Contents/MenuSmall";
import Footer from "../Layout/Footer/footer";
import Header from "../Layout/Header/Header";
import { staticAssets } from "../staticAssets";



const Hata: React.FC = () => {
  return (
    <div>
      <div className="cursor-follower"></div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <section className="error-area space-top pb-425 fix">
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-lg-9">
                  <div className="error-items">
                     <div className="error-image">
                        <img src="src/assets/img/bg/error.png" alt="img"/>
                     </div>
                     <h2>
                        Sayfa Bulumadı
                     </h2>
                     <p>Üzgünüz aradığınız sayfa bulunamadı.</p>
                     <a href="/" className="gt-btn gt-btn-icon wow fadeInUp" data-wow-delay=".8s">
                     Anasayfaya Dön
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="cta-area mt-n150 mb-n116 ">
         <div className="container">
         <div className="cta-wrap style1">
               <div className="shape1_1 rotate360 d-none d-xl-block"><img src={staticAssets.CtaShape_1}
                  alt="shape"/></div>
               <div className="shape1_2 d-none d-xl-block"><img src={staticAssets.CtaShape_2} alt="shape"/>
               </div>
               <div className="shape1_3 d-none d-xl-block"><img src={staticAssets.CtaShape_3} alt="shape"/>
               </div>
               
               <h4 className="cta-title text-white">Yenilikçi Teknolojilerimizle Bağlantıda Kalın</h4>
               <div className="btn-wrapper">
                  <a className="gt-btn style5 gt-btn-icon " href="/iletisim">BİZE ULAŞIN</a>
               </div>
            </div>
         </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Hata;