import  { useEffect, useState } from "react";
import Background from "../../../src/assets/img/bg/pricingBg1_1.png";
import NewsDto from "../Dtos/NewsDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
// Ortak fetch fonksiyonu


const MainNews = () => {
  const [news, setNews] = useState<NewsDto[]>([]);
  useEffect(() => {
    fetch(url+ "news/GetNews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pageSize: 12, page: 0, orderBy: "asc" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setNews(data.news)
        //console.log(contents?.Address)
        //data.sliders.map((item)=> console.log(item))
        
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  return (
    <section className="blog-area fix">
      <div
        className="blog-wrap style1 space-top pb-425"
        style={{ background: `url(${Background})` }}
      >
        <div className="shape1_1 movingX d-xl-block d-none">
          <img
            src={staticAssets.BlogShape}
            alt="shape"
          />
        </div>
        <div className="container">
          <div className="title-area mx-auto mt-60">
            {/* <h5 className="subtitle text-center wow fadeInUp" data-wow-delay=".3s"> <span><img src="../../../src/assets/img/icon/titleIcon.png"
                alt="icon"/></span>
                Bizden Haberler<span className="ms-1"><img src="../../../src/assets/img/icon/titleIcon.png" alt="icon"/></span>
             </h5> */}
            <h2
              className="title text-center mb-50 wow fadeInUp"
              data-wow-delay=".3s"
            >
              Bizden Haberler
            </h2>
          </div>
          <div className="blog-card-wrap style1" >
          {news.map((item, index) => (
           
              <div
                className="blog-card style1  img-shine wow fadeInUp"
                data-wow-delay=".3s" key={index}
              >
                <div className="blog-card-thumb style1">
                  <img src={item.image} alt="thumb" />
                </div>
                <div className="blog-card-body">
                  <div className="tag-cloud"></div>
                  <h3 className="blog-title style1">
                    <a href={'/haber/'+ item.link + '/' + item.id} >
                    
                    {item.title}
                    </a>
                  </h3>
                  <div className="btn-wrapper">
                    <a href={'/haber/'+ item.link + '/' + item.id}>
                      Devamı{" "}
                      <img
                        src={staticAssets.ArrowRightDark}
                        alt="icon"
                      />
                    </a>
                  </div>
                  <div className="calendar">
                    <div className="date">{item.dateTime}</div>
                  </div>
                </div>
              </div>
           
          ))}
          ;
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainNews;
