import { useEffect, useState } from "react";
import BaseContent from "../Dtos/BaseContentsDto";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
const HeaderBig = () => {
  const [contents,setContents] = useState<BaseContent>();
    
      useEffect(() => {
        fetch(url +"basecontent/GetBaseContent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            //console.log(data.mail);
            setContents(data)
            //console.log(contents?.Address)
            //data.sliders.map((item)=> console.log(item))
            
          })
          .catch((error) => console.log("Error fetching images:", error));
      }, []);
    return (  
        <div className="sidemenu-wrapper sidemenu-info d-none d-lg-block ">
        <div className="sidemenu-content">
          <button className="closeButton sideMenuCls">
            <i className="far fa-times"></i>
          </button>
          <div className="widget  ">
            <div className="gt-widget-about">
              <div className="about-logo">
                <a href="/">
                  <img src={contents?.logoPath} alt="techbe" />
                </a>
              </div>
              <p className="text">
                {" "}
                Bizimle iletişimde kalın! Her türlü soru, öneri veya geri
                bildiriminiz için size yardımcı olmaktan memnuniyet duyarız.
                Form aracılığıyla mesajınızı iletin, en kısa sürede size geri
                dönüş yapalım.
              </p>
            </div>
          </div>
          <div className="widget  ">
            <h3 className="widget_title">İletişim</h3>
            <div className="gt-widget-contact">
              <div className="info-box_text">
                <div className="icon">
                  <img src={staticAssets.LocationDotSvg} alt="img" />
                </div>
                <div className="details">
                  <p>{contents?.address}</p>
                </div>
              </div>
              <div className="info-box_text">
                <div className="icon">
                  <img src={staticAssets.PhoneSvg} alt="img" />
                </div>
                <div className="details">
                 
                  <p>
                    <a href={"tel:"+contents?.phone} className="info-box_link">
                    {contents?.phone}
                    </a>
                  </p>
                </div>
              </div>
              <div className="info-box_text">
                <div className="icon">
                  <img src={staticAssets.EnvelopeSvg} alt="img" />
                </div>
                <div className="details">
                  <p>
                    <a
                      href={contents?.mail}
                      className="info-box_link"
                    >
                      {contents?.mail}
                    </a>
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="widget newsletter-widget  ">
           
            <div className="gt-social mt-4">
              <a href={contents?.facebook}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href={contents?.twitter}>
                <i className="fab fa-twitter"></i>
              </a>
              <a href={contents?.linkedin}>
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href={contents?.instagram}>
                <i className="fab fa-instagram"></i>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    );
};

export default HeaderBig;

