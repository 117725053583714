import React from "react";
import HeaderBig from "../Contents/HeaderBig";
import MenuSmall from "../Contents/MenuSmall";
import Footer from "../Layout/Footer/footer";
import Header from "../Layout/Header/Header";
import HaberlerComp from "../Contents/HaberlerComp";

// interface RouteParams {
//   title: string;
//   id:string
// }

const Haberler: React.FC = () => {
  //   const { title,id} = useParams<RouteParams>();

  return (
    <div>
      <div className="cursor-follower"></div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <HaberlerComp></HaberlerComp>
      <Footer></Footer>
    </div>
  );
};

export default Haberler;
