
import Background from '../../../../src/assets/img/bg/footerBg1.png';
import  { useEffect, useState } from "react";
import BaseContent from '../../Dtos/BaseContentsDto';
import NewsDto from '../../Dtos/NewsDto';
import { staticAssets } from '../../staticAssets';
const url = process.env.REACT_APP_BACKEND;

const Footer = () => {
    const [contents,setContents] = useState<BaseContent>();
    
      useEffect(() => {
        fetch(url +"basecontent/GetBaseContent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            //console.log(data.mail);
            setContents(data)
            //console.log(contents?.Address)
            //data.sliders.map((item)=> console.log(item))
            
          })
          .catch((error) => console.log("Error fetching images:", error));
      }, []);
      const [news,setNews] = useState<NewsDto[]>([]);
      useEffect(() => {
        fetch(url+"news/Getnews", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify({"pageSize":2,"page":0,"orderBy":"asc"})
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            //console.log(data.mail);
            setNews(data.news)
            //console.log(contents?.Address)
            //data.sliders.map((item)=> console.log(item))
            
          })
          .catch((error) => console.log("Error fetching images:", error));
      }, []);

  return (
<footer className="footer-area" style={{background: `url(${Background})`,  }}  >

<div className="widget-area style1  pt-100 pb-80">
   <div className="shape2_1"> <img src={staticAssets.Footer_1} alt="shape"/> </div>
   <div className="shape2_2"> <img src={staticAssets.Footer_2} alt="shape"/> </div>
   <div className="container">
      <div className="footer-layout style1">
         <div className="row">
            <div className="col-xl-3 col-md-6 col-12 zindex-1">
               <div className="widget footer-widget wow fadeInUp" data-wow-delay=".6s">
                  <div className="gt-widget-about">
                     <div className="about-logo">
                        <a href="/"><img src={contents?.logoPath} alt="Realar"/></a>
                     </div>
                     <p className="about-text"> {contents?.footerDescription}
                     </p>
                     <div className="gt-social style2">
                        <a href={contents?.facebook}><i className="fab fa-facebook-f"></i></a>
                        <a href={contents?.twitter}><i className="fab fa-twitter"></i></a>
                        {/* <a href={contents.instagram}><i className="fab fa-youtube"></i></a> */}
                        <a href={contents?.instagram}><i className="fab fa-instagram"></i></a>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-xl-2 col-md-6 col-12">
               <div className="widget widget_nav_menu footer-widget wow fadeInUp" data-wow-delay="1s">
                  <h3 className="widget_title">Linkler</h3>
                  <div className="menu-all-pages-container">
                     <ul className="menu">
                        <li><a href="/hakkimizda"><i className="fa-solid fa-chevrons-right"></i>Hakkımızda</a>
                        </li>
                        <li><a href="/hizmetlerimiz"><i className="fa-solid fa-chevrons-right"></i>Hizmetlerimiz</a></li>
                        <li><a href="/garanti-sorgulama"><i className="fa-solid fa-chevrons-right"></i>Garanti Sorgulama</a>
                        </li>
                        <li><a href="/haberler"><i className="fa-solid fa-chevrons-right"></i>Haberler</a></li>
                        <li><a href="/iletisim"><i className="fa-solid fa-chevrons-right"></i>İletişim</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
               <div className="widget footer-widget wow fadeInUp" data-wow-delay="1.3s">
                  <h3 className="widget_title">Son Haberler</h3>
                  
                  <div className="recent-post-wrap">
                    {news.map((item,index)=>
                        (
                            <div className="recent-post" key={index} >
                            <div className="media-img">
                               <a href="blog-details.html"><img
                                  src={item.image} alt="thumb"/></a>
                            </div>
                            <div className="media-body">
                               <div className="recent-post-meta">
                                  <a href={'/haber/' + item.link +'/'+ item.id}><i className="fa-regular fa-clock"></i>{item.dateTime}</a>
                               </div>
                               <h4 className="post-title"><a className="text-inherit" href={'/haber/' + item.link +'/'+ item.id}>{item.shortContents}</a>
                               </h4>
                            </div>
                         </div>
                        )
                    )}
                    
                    
                  </div>
               </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
               <div className="widget widget_nav_menu footer-widget wow fadeInUp" data-wow-delay="1.6s">
                  <h3 className="widget_title">İletişim</h3>
                  <div className="checklist">
                     <ul className="ps-0">
                        <li className="text-white"><i className="fa-thin fa-envelope"></i></li>
                        <li className="text-white"><a href={"mailto:"+contents?.mail} >{contents?.mail}</a></li>
                     </ul>
                     <ul className="ps-0">
                        <li className="text-white"><i className="fa-light fa-phone-volume"></i></li>
                        <li className="text-white"><a href={"tel:"+contents?.phone} >{contents?.phone}</a></li>
                     </ul>
                     <ul className="ps-0">
                        <li className="text-white"><i className="fa-light fa-solid fa-house"></i></li>
                        <li className="text-white">{contents?.address}</li>
                     </ul>
                     {/* <div className="email-input-container">
                        <input type="email" id="email" placeholder="Your email address" required/>
                        <button type="submit" id="submitButton" disabled><i
                           className="fa-regular fa-arrow-right-long"></i></button>
                     </div> */}
                     {/* <form id="termsForm">
                        <label className="custom-checkbox">
                        <input type="checkbox" name="agree" id="agreeCheckbox"/>
                        <span className="checkmark"></span>
                        I agree to the <a className="text-underline" href="contact.html" target="_blank">Privacy
                        Policy.</a>
                        </label>
                        <br/>
                     </form> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div className="copyright-wrap bg-theme">
   <div className="container">
      <div className="copyright-layout">
         <div className="layout-text wow fadeInUp" data-wow-delay=".3s">
            <p className="copyright">
               <i className="fal fa-copyright"></i> Tüm hakları saklıdır {(new Date()).getFullYear()} <a href="/">{contents?.companyName}</a>
            </p>
         </div>
          <div className="layout-link wow fadeInUp" data-wow-delay=".6s">
            <div className="link-wrapper">
               <a href="/haberler">Haberler </a>
               <a href="/iletisim">İletişim</a>
            </div>
         </div>
      </div>
   </div>
</div>
</footer>
  );
};

export default Footer;