import Header from "../Layout/Header/Header";
import MainSlider from "../Contents/MainPageSlider";
import Footer from "../Layout/Footer/footer";
import MainServices from "../Contents/MainServices";
import MainCounterArea from "../Contents/MainCounterArea";
import MainNews from "../Contents/MainNews";
import MenuSmall from "../Contents/MenuSmall";
import HeaderBig from "../Contents/HeaderBig";

function index() {
  return (
    <div>
      <div className="cursor-follower"></div>
      <HeaderBig></HeaderBig>
      <MenuSmall></MenuSmall>
      <Header />
      <main className="main position-relative" id="mains">
        <section className="hero-1" id="hero">
          <MainSlider />
        </section>
        <MainServices></MainServices>
        <MainCounterArea></MainCounterArea>
        <MainNews></MainNews>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default index;
