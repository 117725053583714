import React,{ useEffect, useRef, useState } from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Slider from "../Dtos/Slider";
import { staticAssets } from "../staticAssets";
const url = process.env.REACT_APP_BACKEND;
const ImageComponent = () => {
  const [images, setImages] = useState<Slider[]>([]);
  const [htmlContent, setHtmlContent] = useState<any>();
  useEffect(() => {
    fetch(url + "basecontent/GetSliders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data:any) => {
       
        //data.sliders.map((item)=> console.log(item))
        //const base64Images = data.sliders.map((item:Slider) => `${item}`);
        console.log(data);
        setImages(data);
        setHtmlContent(data)
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  return (
<Swiper
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      spaceBetween={10}
      centeredSlides={false}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      freeMode={true}
      loop={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="me-1"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}
        
        >
          <img src={image.image} alt={`Slider ${index + 1}`} height={500} />
          <h2
            style={{
              position: "absolute",
              top: "75%",
              left: "45%",
              transform: "translate(-50%, -50%)",
              color: "#ededed",
              fontSize: "60px",
              height:"600px",
              fontFamily:"Plus Jakarta Sans",

              
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: image.text }}></div>
          </h2>
        </SwiperSlide>
      ))}
      <div style={{ margin: "-50px -50px" }}>
        <button
          data-slider-prev="#heroSlide1"
          className="slider-arrow style2"
          ref={navigationNextRef}
        >
          <img src={staticAssets.ArrowLeft} alt="img" />
        </button>
        <button
          data-slider-next="#heroSlide1"
          className="slider-arrow style2 slider-next"
          ref={navigationPrevRef}
        >
          <img src={staticAssets.ArrowRight} alt="img" />
        </button>
        {/* <div className="scroll-down">
               <a href="#service-area" className="hero-scroll-wrap"><i className="fal fa-long-arrow-left"></i>Scroll</a>
            </div> */}
        <button ref={navigationNextRef}>Next</button>
        <button ref={navigationPrevRef}>Prev</button>
      </div>
    </Swiper>
  );
};



    // <section className="hero-1" id="hero">
    //         <div className="swiper gt-slider hero-slider1" id="heroSlide1"
    //            data-slider-options='{"effect":"fade", "autoHeight": "true"}'>
    //            <div className="swiper-wrapper">
    //               <div className="swiper-slide">
    //                  <div className="hero-inner">
    //                     <div className="gt-hero-bg" data-bg-src="/src/assets/img/hero/hero-bg1_1.png"></div>
    //                     <div className="container">
    //                        <div className="row align-items-center">
    //                           <div className="col-xl-8">
    //                              <div className="hero-style1">
    //                                 <h6 className="hero-sub-title" data-ani="slideindown" data-ani-delay="0.2s"><img className="me-1" src="/src/assets/img/icon/titleIconWhite.png" alt="icon"/>BEST
    //                                    IT
    //                                    SOULTION PROVIDER
    //                                 </h6>
    //                                 <h1 className="hero-title text-white">
    //                                    <span className="title1" data-ani="slideindown" data-ani-delay="0.3s">
    //                                    IT Consulting Services</span>
    //                                    <span className="title2" data-ani="slideindown" data-ani-delay="0.4s">
    //                                    For Your Business </span>
    //                                 </h1>
    //                                 <p className="hero-text text-white" data-ani="slideinup" data-ani-delay="0.5s">
    //                                    Professionally optimize interdependent intellectual interoperable
    //                                    connect
    //                                    best practices. Progressively fabricate done
    //                                 </p>
    //                                 <a href="contact.html" className="gt-btn gt-btn-icon" data-ani="slideinup"
    //                                    data-ani-delay="0.6s">EXPLORE MORE</a>
    //                              </div>
    //                           </div>
    //                        </div>
    //                     </div>
    //                  </div>
    //               </div>
    //               <div className="swiper-slide">
    //                  <div className="hero-inner">
    //                     <div className="gt-hero-bg" data-bg-src="/src/assets/img/hero/hero-bg1_2.png"></div>
    //                     <div className="container">
    //                        <div className="row align-items-center">
    //                           <div className="col-xl-8">
    //                              <div className="hero-style1">
    //                                 <h6 className="hero-sub-title" data-ani="slideindown" data-ani-delay="0.2s"><img className="me-1" src="/src/assets/img/icon/titleIconWhite.png" alt="icon"/>BEST
    //                                     IT
    //                                     SOULTION PROVIDER
    //                                  </h6>
    //                                 <h1 className="hero-title text-white">
    //                                    <span className="title1" data-ani="slideindown" data-ani-delay="0.3s">
    //                                    IT Consulting Services</span>
    //                                    <span className="title2" data-ani="slideindown" data-ani-delay="0.4s">
    //                                    For Your Business </span>
    //                                 </h1>
    //                                 <p className="hero-text text-white" data-ani="slideinup" data-ani-delay="0.5s">
    //                                    Professionally optimize interdependent intellectual interoperable
    //                                    connect
    //                                    best practices. Progressively fabricate done
    //                                 </p>
    //                                 <a href="contact.html" className="gt-btn gt-btn-icon" data-ani="slideinup"
    //                                    data-ani-delay="0.6s">EXPLORE MORE</a>
    //                              </div>
    //                           </div>
    //                        </div>
    //                     </div>
    //                  </div>
    //               </div>
    //               <div className="swiper-slide">
    //                  <div className="hero-inner">
    //                     <div className="gt-hero-bg" data-bg-src="/src/assets/img/hero/hero-bg1_2.png"></div>
    //                     <div className="container">
    //                        <div className="row align-items-center">
    //                           <div className="col-xl-8">
    //                              <div className="hero-style1">
    //                                 <h6 className="hero-sub-title" data-ani="slideindown" data-ani-delay="0.2s"><img className="me-1" src="/src/assets/img/icon/titleIconWhite.png" alt="icon"/>BEST
    //                                     IT
    //                                     SOULTION PROVIDER
    //                                  </h6>
    //                                 <h1 className="hero-title text-white">
    //                                    <span className="title1" data-ani="slideindown" data-ani-delay="0.3s">
    //                                    IT Consulting Services</span>
    //                                    <span className="title2" data-ani="slideindown" data-ani-delay="0.4s">
    //                                    For Your Business </span>
    //                                 </h1>
    //                                 <p className="hero-text text-white" data-ani="slideinup" data-ani-delay="0.5s">
    //                                    Professionally optimize interdependent intellectual interoperable
    //                                    connect
    //                                    best practices. Progressively fabricate done
    //                                 </p>
    //                                 <a href="contact.html" className="gt-btn gt-btn-icon" data-ani="slideinup"
    //                                    data-ani-delay="0.6s">EXPLORE MORE</a>
    //                              </div>
    //                           </div>
    //                        </div>
    //                     </div>
    //                  </div>
    //               </div>
    //            </div>
    //         </div>
    //         <button data-slider-prev="#heroSlide1" className="slider-arrow style2"><img
    //            src="src/assets/img/icon/arrowLeft.png" alt="img"/></button>
    //         <button data-slider-next="#heroSlide1" className="slider-arrow style2 slider-next"><img
    //            src="src/assets/img/icon/arrowRight.png" alt="img"/></button>
    //         <div className="scroll-down">
    //            <a href="#service-area" className="hero-scroll-wrap"><i className="fal fa-long-arrow-left"></i>Scroll</a>
    //         </div>
    //      </section>


export default ImageComponent;
