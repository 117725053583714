import { useEffect, useState } from "react";
import BaseContent from "../../Dtos/BaseContentsDto";
import ServiceDto from "../../Dtos/ServiceDto";
import { staticAssets } from "../../staticAssets";

const url = process.env.REACT_APP_BACKEND;

const Header = () => {
  const [contents, setContents] = useState<BaseContent>();

  useEffect(() => {
    fetch(url + "basecontent/GetBaseContent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setContents(data);
        //console.log(contents.mail)
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  const [services, setNews] = useState<ServiceDto[]>([]);
  useEffect(() => {
    fetch(url + "services/GetServices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data.mail);
        setNews(data.ourService);
        //console.log(contents?.Address)
        //data.sliders.map((item)=> console.log(item))
      })
      .catch((error) => console.log("Error fetching images:", error));
  }, []);

  return (
    <div>
      <div className="header-top-section style1 fix">
        <div className="container">
          <div className="header-top-wrapper">
            <ul className="contact-list">
              <li>
                <i className="far fa-envelope"></i>
                <a href={"mailto:" + contents?.mail} className="link">
                  {contents?.mail}
                </a>
              </li>
              <li>
                <i className="fa-solid fa-phone-volume"></i>
                <a href={"tel:" + contents?.mail}>{contents?.phone}</a>
              </li>
            </ul>
            <div className="top-right">
              <div className="social-icon d-flex align-items-center">
                <span>Sosyal Medya:</span>
                <a href={contents?.facebook}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href={contents?.twitter}>
                  <i className="fab fa-twitter"></i>
                </a>
                <a href={contents?.linkedin}>
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                <a href={contents?.instagram}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="gt-header header-layout1">
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <a href="/">
                      <img src={contents?.logoPath} alt="techbe" />
                    </a>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-xl-inline-block">
                    <ul>
                      <li>
                        <a href="/">Anasayfa</a>
                      </li>
                      <li>
                        <a href="/hakkimizda">Hakkımızda</a>
                      </li>
                      <li className="menu-item-has-children">
                        <a href="/hizmetlerimiz"> Hizmetlerimiz </a>

                        <ul className="sub-menu">
                          {services.map((item, index) => (
                            <li key={index}>
                              <a
                                href={
                                  "/hizmetlerimiz/" + item.link + "/" + item.id
                                }
                              >
                                {item.serviceTitle}
                              </a>
                            </li>
                          ))}
                          {/* <li> <a href="service-1.html"> CLIENT ÇÖZÜMLERİ</a> </li>
                               <li> <a href="service-2.html"> NETWORK ÇÖZÜMLERİ</a> </li>
                               <li> <a href="service-details.html">WORKSTATION & SERVER</a> </li> */}
                        </ul>
                      </li>

                      <li>
                        <a href="/urunler">Ürünlerimiz</a>
                      </li>
                      <li className="menu-item-has-children">
                        <a href="blog-grid.html"> Destek </a>
                        <ul className="sub-menu">
                          <li>
                            <a href="/garanti-sorgulama">Garanti Sorgulama</a>
                          </li>
                          <li>
                            <a href="servis-kaydi-sorgulama">
                              Servis Kaydı Sorgulama
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/iletisim">İletişim</a>
                      </li>
                    </ul>
                  </nav>
                  <div className="header-button d-flex d-xl-none">
                    <button
                      type="button"
                      className="gt-menu-toggle sidebar-btn"
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </button>
                  </div>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="header-button">
                    {/* <button type="button" className="simple-icon searchBoxToggler"><i
                         className="far fa-search"></i></button>  */}
                    <a href="/iletisim" className="gt-btn gt-btn-icon">
                      BİZE ULAŞIN
                    </a>
                    <button
                      type="button"
                      className="simple-icon sideMenuInfo sidebar-btn"
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
